.skts-btn-file {
    position: relative;
    overflow: hidden;
    input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
    }
    &.skts-full-width {
        margin-bottom: 0.3em;
    }
}

.has-error, .skts-has-error-server {
    .skts-btn-file {
        border-color: #A00000;
    }
}

a {
    cursor: pointer;
}

option[value=""][disabled] {
    display: none;
}

option {
    color: black;
}

.cursor-hand {
    cursor: pointer;
}

.etage {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.lejlighed {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
}

.indberetningsIdLeft {
    padding-right: 0px;
    input {
        border-right: 0px;
        text-align: right;
        padding-right: 2px;
    }
}

.indberetningsIdRight {
    padding-left: 0px;
    input {
        border-left: 0px;
        padding-left: 2px;
    }
}

.compressedWell {
    margin-bottom: 10px;
}

.buttonRow {
    margin-bottom: 10px;
}

.skts-icon--slet-hvid {
    background-image: url(../assets/skat/images/ikoner/ikon-slet-hvid.svg)
}

ul.navbar-center {
    display: flex;
    justify-content: center;
}

table {
    table-layout: fixed;
}

td {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.noBtmMargin {
    margin-bottom: 0px !important;
}

.noBtmBorder {
    border-bottom: 0px !important;
}

.deletedRow {
    color: #b9b9b9;
    text-decoration: line-through;
}

.noPaddingLeft {
    padding-left: 0px;
}

div.scrollable {
    overflow-y: auto;
    max-height: 50vh;
    width: 100%;
    margin-bottom: 5px;
    border-bottom: 2px solid grey;
}

.skts-user-info {
    padding-bottom: 0;
}

.filename {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.transparent {
    opacity: 0.5;
    filter: alpha(opacity=50); /*!* msie *!*/
}

.only-print {
    display: none;
}

$margin-large: 60px;
$margin-medium: 40px;
$margin-overview-header: 25px;
$margin-small: 20px;

.tilkendegivelse-wrapper {
    margin-bottom: $margin-medium;
}

@media print {

    header {
        display: none;
    }

    .skts-user-info {
        margin: 0;
    }

    $print-block-margin: 25px;

    h1 {
        padding: 0;
        margin-top: $print-block-margin * 2;
        margin-bottom: $print-block-margin * 2;
    }

    hr {
        margin-top: $print-block-margin;
        margin-bottom: $print-block-margin;
    }

    .tilkendegivelse-wrapper {
        margin-bottom: $print-block-margin * 2;
    }

    .dropdown-link {
        padding-bottom: 8px;
    }

    .scroll-on-overflow {
        overflow: inherit !important;
    }

    .col-md-9 {
        display: inline-block;
        box-sizing: border-box;
        width: 75%;
    }

    .col-md-3 {
        display: inline-block;
        box-sizing: border-box;
        width: 25%;
    }

    .corrected-tilkendegivelser-wrapper * {
        color: dimgray !important;
    }

    .well {
        padding: 0;
    }
    .skts-user-info {
        padding-left: 0;
        padding-right: 0;
    }
    .information {
        width: 100% !important;
    }
    .filename {
        overflow-x: visible;
        white-space: normal;
    }
    .no-print {
        display: none;
    }
    .only-print {
        display: block;
    }

    .transparent {
        opacity: 1;
        filter: alpha(opacity=100); /*!* msie *!*/
    }
}

.indberetningsType {
    font-weight: bold;
}

.reportingPeriodList {
    margin-top: $margin-overview-header;
}

// Used to prevent bootskat hiding results of backend se-validation
.custom-skts-error-text {
    color: #A00000;
    display: none;
    font-size: .8em;
    padding-top: .4em;
}

.custom-has-error .form-control {
    border-color: #A00000;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.custom-has-error .form-control:focus {
    border-color: #6d0000;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ff0707;
}

.well-row {
    margin-bottom: $margin-small;
}

.inline {
    display: inline;
}

.pdf-only {
    display: none;
}

.pdf-visible {
    .pdf-only {
        display: block;
    }

    header {
        margin-bottom: $margin-medium;
    }

    h1 {
        margin-bottom: $margin-medium;
    }

    h2 {
        margin-bottom: $margin-small;
    }

    h3 {
        margin-bottom: $margin-small;
    }

    .pdf-body {
        margin-left: $margin-large;
        margin-right: $margin-large;
    }

    .pdf-footer {
        position: relative;
    }
}

.du-kan-rette {
    margin-top: $margin-small;
    div {
        display: inline;
    }
}

.du-kan-laese {
    div {
        display: inline;
    }
}

.venlig-hilsen {
    margin-top: $margin-small;
}

.omsaetningPeriodeHeader {
    margin-bottom: 0;
}

.regnskabsperiode-hr {
    margin-top: 5px;
    margin-bottom: 15px;
}

$icon-color: #434363;
$icon-color-focus: #14143c;

.skts-icon--ret {
    width: 29px;
    height: 29px;
    border-radius: 14.5px;
    background-size: 60%;
    background-color: $icon-color;
    background-image: url(../assets/skat/images/ikoner/ikon-ret--hvid.svg);
}

.skts-icon--pdf-round {
    width: 29px;
    height: 29px;
    border-radius: 14.5px;
    background-size: 54%;
    background-color: $icon-color;
    background-position-y: 41%;
    background-image: url(../assets/skat/images/ikoner/ikon-download-kvittering--hvid.svg);
}

.screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

#tilkendegivelse-description-wrapper {
    margin-bottom: $margin-overview-header;
}

.bold {
    font-weight: bold;
}

.tilkendegivelse-view-row {
    margin-bottom: $margin-small;
}

.fd-day-header, .date-picker-day-header {
    text-transform: none;
}

.date-picker .date-picker-day-header {
    text-transform: none;
}
